.custom-context-menu {
  position: absolute !important;
  z-index: 1000;
  background-color: var(--primary) !important;
  color: var(--bg-secondary) !important;
  border-radius: 5px;
  margin: 0;
  display: none;
  list-style: none;
  /* box-shadow: 0 2px 5px var(--primary); */
  animation: contextGrow 0.3s ease-in-out normal forwards;
  transition: left 0.3s ease-in-out, top 0.3s ease-in-out;
  opacity: 0;
  border: 2px solid var(--bg-secondary);
}

.context-menu-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.context-menu-option > span {
  width: 100%;
  height: 100%;
  padding: 7.5px 10px;
}

.context-menu-option > i {
  background-color: var(--bg-secondary);
  color: var(--primary);
  height: 100%;
  width: 30px;
  padding: 10px;
  display: flex;
}

.context-menu-option:last-child,
.context-menu-option:last-child > i {
  border-radius: 0px 0px 0px 2px;
}
.context-menu-option:first-child,
.context-menu-option:first-child > i {
  border-radius: 2px 0px 0px 0px;
}

.context-menu-option.circle {
  width: 50px;
  aspect-ratio: 1/1;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s;
  font-size: 1em;
  border: 2px solid var(--bg-secondary);
}
.context-menu-option.circle > i {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  color: inherit;
  background-color: inherit;
  border-radius: 50%;
}
.context-menu-option:not(.context-menu-option.circle):hover i {
  animation: fillRight 0.5s ease-in-out normal forwards;
}
.context-menu-option:not(.context-menu-option.circle):hover span {
  animation: fillBackgroundColorRight 0.3s ease-in-out normal forwards;
}

.context-menu-option.circle:hover i {
  animation: fillBackgroundColorRight 0.3s ease-in-out normal forwards;
}

.context-menu-source-container {
  display: inline-block;
  color: var(--bg-secondary);
  padding: 5px 10px;
  border: 2px solid var(--bg-secondary);
  background-color: var(--primary);
  border-radius: 5px;
  cursor: default;
}

@keyframes contextGrow {
  0% {
    transform: scale(0) rotate(-15deg);
    opacity: 1;
  }
  50% {
    transform: scale(1.1) rotate(5deg);
    opacity: 0.7;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

@keyframes contextHide {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(0.8) rotate(10deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(0) rotate(50deg);
    opacity: 0;
  }
}

@keyframes fillRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    background-color: var(--bg-secondary);
  }
}

@keyframes fillBackgroundColorRight {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    background-color: var(--bg-secondary);
    width: 100%;
    color: var(--primary);
  }
}
