/* Component card styling */
.component-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s, box-shadow 0.3s;
  width: 95%;
}

/* .component-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
} */

.component-card h2 {
  font-size: 1.5rem;
  width: fit-content;
  color: var(--bg-secondary);
  cursor: pointer;
}

.component-card h2:hover {
  color: var(--primary);
}

.component-card p {
  color: var(--text-muted);
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.component-content {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px;
}

.component-content > * {
  height: fit-content;
}

.component-card-header {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--secondary);
}

.component-card-header h2 {
  width: 90%;
}

.component-card-header button {
  background-color: var(--bg-primary) !important;
  border: 2px solid var(--primary);
  width: fit-content;
  margin: 0 10px;
  border-radius: 2px;
  color: var(--dark);
  text-transform: uppercase;
}

.component-card-header button:hover {
  background-color: var(--primary) !important;
  border: 2px solid var(--primary);
}

/* SyntaxHighlighter styling */
.syntax-highlighter {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .component-card {
    padding: 10px;
  }

  .component-card h2 {
    font-size: 1.2rem;
  }

  .component-card p {
    font-size: 0.8rem;
  }

  .component-card-header button {
    font-size: 0.8rem;
  }
}
