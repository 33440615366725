.animated-div {
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0.1;
    transform: translateX(-20vw);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(10vw);
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-50vh);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideOutTop {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50vh);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
