/* Showcase container styling */
.showcase-container {
  position: relative;
  max-width: 90%;
  margin: 0 auto;
  padding: 40px;
  z-index: 1;
}

.showcase-container h1 {
  font-size: 2.5rem;
  color: var(--bg-pr);
  text-align: center;
  margin-bottom: 40px;
  opacity: 0;
  animation: blur 1s ease-in-out forwards 0.3s;
}

/* Showcase grid styling */
.showcase-grid {
  display: grid;
  gap: 20px;
}

.vertical-grid {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.horizontal-grid {
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .showcase-container {
    padding: 20px;
  }

  .showcase-container h1 {
    font-size: 2rem;
  }
}
