/* set up variable colours */
:root {
  --primary: #007bff;
  --secondary: #94bb86;
  --bg-primary: #b8d1ae;
  --bg-secondary: #2d2d2a;
  --dark: #2d2d2a;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --black: rgb(27, 27, 27);
  --white: white;
  --light-grey: #f8f9fa;
  --offwhite: #f5f5f5;
  --primary-hover: #0056b3;
  --secondary-hover: #1a1a1a;
  --font: "Inter", sans-serif;
  --alt-font: "Barlow", sans-serif;
  --sideBoxWidth: 5%;
}

/* Reset some default styles in browsers */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Set the background, font-family, and text color for the entire page */
html,
body {
  color: var(--secondary);
  font-family: "IBM Plex Mono", monospace;
  background-color: var(--bg-primary);
  margin: 0;
  padding: 0;
}

.main {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: row !important;
  align-items: baseline;
}

.wrap {
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.blue {
  background-color: var(--blue);
}

.transparent {
  background-color: transparent;
}

.scroll-y {
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch; /* enables momentum-scrolling on iOS */
}

.no-margin {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: var(--black);
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  width: 3px;
}

.work {
  color: var(--bg-primary);
}

.work a {
  color: var(--bg-primary) !important;
}

.vertical-line {
  border-left: 1px solid #007bff;
  height: 200px;
  margin-bottom: 400px;
  margin-top: 0px;
}

.work-line {
  border-left: 1px solid #007bff;
  height: 90%;
  margin-bottom: 400px;
  margin-top: 0px;
}

/* Style the title box */
.title-box {
  align-self: flex-end;
  text-align: left;
  width: 40%;
  padding-left: 10%;
  padding-right: 5%;
  padding-bottom: 20%;
}

.title-box h1 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: var(--primary);
}

.title-box p {
  font-size: 0.9em;
  margin-bottom: 20px;
}

/* Style the navigation box */
.nav-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
  width: 70%;
  height: 90%;
  padding: 0%;
  margin-right: 10px;
  animation: blur 2s ease-out;
  animation-delay: 0s;
}

.nav-box.work {
  margin-top: 0px;
  overflow-y: scroll;
  overflow-x: inherit;
}

.nav-box.work ul {
  padding: 0%;
  margin-top: -4%;
}

.nav-box.work li a span {
  color: #007bff;
}

.nav-box.work ul > ul {
  margin-left: 10px;
}

.nav-box.worl li a:hover {
}

.nav-box ul {
  list-style-type: none;
}

.nav-box li {
  margin-top: -20px;
  padding: 0px;
  font-size: 14vw;
  line-height: 14vw;
}

.nav-box li a {
  text-decoration: none;
  color: #333;
}

.nav-box.work li a {
  font-size: 0.8em;
  color: #b8d1ae;
}

.nav-box.work li {
  padding: 0px !important;
}

/* .nav-box li:nth-child(2) a {
  color: #007bff;
}

.nav-box li:nth-child(2) a:hover {
  color: #333;
} */

/* Add hover effects */
.side-box a:hover,
.nav-box li a:hover {
  transition: all 0.3s ease;
  cursor: pointer;
  color: #007bff !important;
  /* You can change this color to suit your design preference */
}

/* Style the content box */
@keyframes slideIn {
  from {
    top: -20vh;
  }

  to {
    top: 100vh;
  }
}

@keyframes slideInTop {
  from {
    top: -20vw;
  }

  to {
    top: 0vh;
  }
}

@keyframes blur {
  0% {
    opacity: 0;
    filter: blur(10px);
  }

  75% {
    opacity: 0.5;
    filter: blur(0px);
  }

  100% {
    opacity: 1;
  }
}

/* Style the side box */
.side-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 5%;
  height: 95%;
  text-align: left;
  position: fixed;
  left: 0%;
  padding-top: 50px;
  gap: 20px;
}

.side-box a {
  border: none;
  transform: rotate(-90deg);
  background-color: transparent;
  font-family: "IBM Plex Mono", monospace;
  text-decoration: none;
  color: #333;
  font-size: 2vh;
}

.title-box {
  position: relative;
  animation: slideInTop 1s ease-in-out;
  animation-delay: 0.1s;
}

.black-box {
  position: fixed;
  height: 160vh;
  width: 100%;
  background-color: #333;
  animation: slideIn 1s ease-out;
  top: 100vh;
  z-index: 1;
}

.black-box.about {
  animation: slideInAbout 1s ease-out 0s;
  top: -170vh;
}

.black-box.work {
  position: fixed;
  height: 160vh;
  width: 100%;
  background-color: #333;
  animation: slideInReverse 1s ease-out;
  top: 0vh;
  z-index: 1;
}

.nav-box.work {
  animation: appear 1s ease-out;
  animation-delay: 0s;
  z-index: 2;
  opacity: 1;
}

.title-box.work {
  animation: slideInWorkTitle 1s ease-out;
  animation-delay: 0s;
  z-index: 2;
  opacity: 1;
}

.side-box.work {
  animation: appear 1s ease-out;
  animation-delay: 0s;
  z-index: 3;
  opacity: 1;
}

@keyframes slideInReverse {
  from {
    top: 100vh;
  }

  to {
    top: 0vh;
  }
}

@keyframes slideInAbout {
  from {
    top: 0vh;
  }

  to {
    top: -170vh;
  }
}

@keyframes appear {
  from {
    filter: blur(10px);
    opacity: 0;
  }

  to {
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes slideInWorkTitle {
  from {
    top: 100vh;
  }

  to {
    top: 0vh;
  }
}

@media only screen and (max-width: 768px) {
  .landing-container {
    justify-content: space-evenly;
  }
  .title-box {
    width: 100%;
    padding: 10%;
    padding-bottom: 0%;
  }
  .nav-box > ul > li {
    line-height: normal;
    font-size: 70px;
  }
  .nav-box {
    margin-left: 0%;
    width: 90%;
  }

  .nav-box.work {
    height: 50%;
    overflow-y: scroll;
    overflow-x: inherit;
  }
  .nav-box.work li {
    margin-top: 10px;
  }
  .nav-box.work ul > ul {
    margin-top: -10px;
    margin-left: 0px;
  }
  .side-box {
    font-size: 10px;
  }
  .work-line {
    height: 100% !important;
    margin-bottom: 300px;
  }
  .side-box {
    padding-top: 20px;
    height: 98%;
    overflow: hidden;
  }
}
