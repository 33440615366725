.workpage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding-left: var(--sideBoxWidth);
}

.landing-container.workpage {
  background-color: white;
}

.workpage-box {
  width: 95%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 100px;
}

.red {
  border-left: 1px solid #8f001a;
}
.workpage-box h1 {
  color: white !important;
}

.workpage-box h1 {
  font-size: 300px;
  animation: topIn 1s ease-in-out;
  margin-bottom: -50px;
}

.workpage-box p {
  color: white;
  font-family: "Kalam", cursive;
}

.workpage-info {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  margin-top: 5%;
}

.workpage-info h1 {
  font-size: 2em;
  margin-bottom: 20px;
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
}

.workpage-footer {
  font-family: "Quicksand", sans-serif;
  width: 30%;
  height: 80%;
  margin-left: 33%;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 100;
  line-height: 2;
}

.workpage-footer > button {
  border: none;
  color: white;
  padding: 40px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 300;
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin-bottom: 100px;
  border: 2px solid transparent;
}

.workpage-footer > button:hover {
  /* background-color: white !important;
  color: var(--dark) !important;
  border: 2px solid var(--dark) !important;
  transition: 0.3s ease-in-out; */
}

.workpage-info > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 50px;
  margin: 0 auto;
  font-weight: 300;
}

.workpage-info > div > div {
  padding: 10px;
  width: 40%;
  text-align: left;
  margin-left: 30px;
}

.workpage-info > div > div > h3 {
  margin-bottom: 20px;
  text-decoration: underline;
  font-size: 1.5rem;
}

.workpage-info > div > div > p {
  font-size: 0.8rem;
}

.workpage-img {
  max-width: 90%;
  max-height: 900px;
  margin: 3% auto;
  padding: 10px;
  border: 2px solid #e0e0e0 !important;
}

.workpage-img-p {
  color: var(--bg-secondary);
  font-family: "Quicksand", sans-serif;
  width: 40%;
  height: 80%;
  margin-left: 30%;
  font-size: 20px;
  font-weight: 100;
  line-height: 2;
}

/* Table Styling */
.workpage-table {
  width: 100%;
  margin-bottom: 30px;
  font-family: "Quicksand", sans-serif;
}

.workpage-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.workpage-table th,
.workpage-table td {
  border: 1px solid #e0e0e0;
  padding: 15px;
  text-align: left;
}

.workpage-table th {
  background-color: #f1f1f1;
  color: var(--bg-secondary);
  font-weight: 600;
}

.workpage-table td {
  background-color: #ffffff;
}

.purple {
  background-color: #a9c5c9 !important;
}

.purple-button {
  background-color: #a9c5c9 !important;
  border: 3px solid #a9c5c9 !important;
}

.purple-button:hover {
  background-color: white !important;
  color: #a9c5c9 !important;
}

.purple-line {
  border-left: 1px solid #a9c5c9 !important;
}

.workpage-tools {
  display: flex;
  margin: 3% auto;
  padding-top: 0px;
  width: 70%;
}
.workpage-tools img {
  width: 20%;
}

.workpage-tools img:last-child {
  width: 70%;
}

.smaller {
  width: 50%;
  margin-left: 23%;
}

@keyframes topIn {
  from {
    transform: translateY(-100px);
  }

  to {
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 768px) {
  .workpage-box {
    margin-left: 5px;
    width: 95%;
  }
  .workpage-box h1 {
    font-size: 3em;
    margin-bottom: 10px;
  }
  .workpage-box p {
    font-size: 20px;
  }
  .side-box.workpage {
    top: 4%;
    font-size: 10px;
  }

  .workpage-info {
    padding: 0px;
    padding-left: 5%;
    padding-right: 5%;
    margin: 5% auto;
    width: 100%;
  }
  .workpage-info > h1 {
    font-size: 1.5em;
    margin-bottom: 0px;
  }

  .workpage-table {
    font-size: 0.6em;
  }

  .workpage-info > h1 {
    font-size: 1rem;
    margin-left: 10px;
  }

  .workpage-info > div {
    flex-direction: row;
    align-items: flex-start;
  }

  .workpage-info > div > div {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .workpage-info > div > div > h3 {
    font-size: 1rem;
  }

  .workpage-info > div > div > p {
    font-size: 0.8rem;
  }

  .workpage-img {
    width: 90%;
    margin-left: 5%;
    margin-top: 0%;
    margin-bottom: 5%;
    padding: 0px;
  }

  .workpage-img-p {
    width: 80%;
    margin-left: 10%;
    margin-top: 0%;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .workpage-footer {
    height: 100px;
    width: 80%;
    margin-left: 10%;
    margin-top: 0px;
    line-height: 1.5;
  }

  .workpage-footer > button {
    padding: 20px;
    border-radius: 5px;
    font-size: 1rem;
  }

  .workpage-tools {
    width: 90%;
    margin: 2% auto;
    padding: 0px;
    border: 2px solid #e0e0e0 !important;
  }

  .workpage-tools .workpage-img {
    margin-left: 10px;
    margin-top: 10px;
    border: none !important;
  }
}
