.contact-container {
  width: 100%;
  padding: 5%;
  margin-top: -50px;
  text-align: left;
  z-index: 2;
  color: var(--bgGreen) !important;
  margin-right: -150px;
  margin-left: var(--sideBoxWidth);
}

.contact-container h1 {
  font-size: 300px;
  margin-bottom: 20px;
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  animation: appear 1s ease-out 0s;
}

.contact-title {
  display: flex;
  flex-direction: row;
}

.contact-title > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-click {
  position: absolute;
  transform: translateY(22px);
  z-index: 2;
  font-size: 10px;
  cursor: pointer;
  color: var(--bgGreen) !important;
  background-color: transparent;
}

.contact-container p {
  font-size: 0.8rem;
  font-weight: 100;
  margin-left: 1%;
  margin-bottom: 20px;
  animation: appear 1s ease-out 0s;
}

.contact-container button {
  font-size: 300px;
  border: none;
  border-radius: 100px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  animation: appear 1s ease-out 0s;
  background-color: transparent;
}

.contact-container > p > a {
  color: #007bff;
}

.contact-copied {
  animation: appear 1s ease-out 0s;
  color: #333;
  text-decoration: wavy underline;
  font-size: 0.8rem !important;
  width: 100px;
  margin-top: 20px;
}

.contact-form {
  width: 70%;
  padding: 5%;
  text-align: left;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  border-radius: 2px;
  transition: 0.3s ease-in-out;
  background-color: var(--secondary);
  padding: 50px;
  visibility: hidden;
  font-size: 1em;
}

.contact-form.visible {
  left: 0px;
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    padding: 5%;
    margin-right: 0px;
    margin-left: 50px;
  }

  .contact-container h1 {
    font-size: 100px;
    margin-bottom: 10px;
  }

  .contact-click {
    font-size: 6px;
    transform: translateY(7px);
  }

  .contact-container p {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }

  .contact-container button {
    padding: 0px;
    font-size: 100px;
  }

  .contact-form {
    margin-top: 0;
    margin: 0 auto;
    margin-left: 40px;
    padding: 20px;
    width: 85%;
    height: fit-content;
    font-size: 0.8em !important;
    left: 0px;
    /* Reset the left positioning */
  }

  .landing-container {
    flex-direction: column;
  }
}
