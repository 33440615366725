.cookie-consent {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 800px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.cookie-consent p {
  margin: 0 0 10px 0;
  font-size: 2em;
  text-align: center;
}

.cookie-consent button {
  padding: 5px 20px;
  background-color: #4caf50; /* Primary color */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 2em;
  cursor: pointer;
}

.cookie-consent button:hover {
  background-color: #45a049;
}
